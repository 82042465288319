import React, { useEffect } from "react";
import { routes } from "../../../utils/constants";
import { NavLink } from "react-router-dom";

import "./IndividualTherapy.scss";

export function IndividualTherapy() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="individual-therapy">
            <div className="individual-therapy__header">
                <h1>Terapia individual</h1>
            </div>
            <div className="individual-therapy__content">
                <p>
                    Las sesiones individuales suelen tener una duración de 1
                    hora y una frecuencia semanal, aunque pueden darse
                    excepciones según las necesidades de cada paciente (dos
                    sesiones a la semana, sesiones cada 15 días...). Durante
                    esta hora, trabajaremos juntos para explorar los desafíos
                    emocionales y mentales que estás enfrentando.
                </p>
                <p>
                    En la primera sesión, me enfocaré en establecer una relación
                    de confianza y comprensión mutua contigo, para crear un
                    ambiente seguro en el que explorar tus dificultades. En las
                    sesiones posteriores, utilizaremos diferentes técnicas y
                    enfoques de terapia, para ayudarte a identificar patrones de
                    pensamiento y comportamiento que puedan estar contribuyendo
                    a tus dificultades. El objetivo es ayudarte a cambiar estos
                    patrones y adoptar nuevas formas de pensar y de actuar para
                    mejorar tu bienestar emocional y mental.
                </p>
                <p>
                    Nos enfocaremos en tus necesidades individuales, por lo que
                    adaptaré mi enfoque y técnicas a ti. Al asistir
                    semanalmente, podrás trabajar constantemente en tus desafíos
                    emocionales y mentales, y mantener el impulso y la
                    motivación necesarios para lograr los objetivos
                    terapéuticos.
                </p>
                <p>
                    Esta sesión es una oportunidad para explorar, reflexionar y
                    aprender nuevas formas de abordar tus desafíos emocionales y
                    mentales en un ambiente seguro y de apoyo. Estoy aquí para
                    ayudarte en este viaje, y juntos trabajaremos para mejorar
                    tu bienestar.
                </p>
            </div>
            <div className="individual-therapy__prices">
                <div className="individual-therapy__prices-unique">
                    <h4>Sesión individual</h4>
                    <p>50€</p>
                </div>
                <div className="individual-therapy__prices-bonus">
                    <h4>Bono 4 sesiones</h4>
                    <p>160€</p>
                </div>
            </div>
            <div className="individual-therapy__extra-info">
                <p>* El bono es personal, intransferible y no reembolsable</p>
            </div>
            <div className="individual-therapy__appointment">
                <NavLink
                    to={routes.appointments}
                    className="navbar__content__links-link"
                    activeclassname="active"
                    title="Reserva tu cita"
                >
                    <p>Reserva tu cita</p>
                </NavLink>
            </div>
        </div>
    );
}
