import React, { useRef } from "react";
import Slider from "react-slick";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import "./Carousel.scss";

export function Carousel({ images, objectFit }) {
    const carouselSettings = {
        className: "center",
        centerMode: true,
        dots: true,
        customPaging: function (i) {
            return (
                <div
                    style={{
                        bottom: "60px",
                        width: "8px",
                        height: "8px",
                        borderRadius: "50%",
                    }}
                ></div>
            );
        },
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnHover: true,
        responsive: [
            {
                breakpoint: 990,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    centerMode: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2,
                    centerMode: true,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                },
            },
        ],
    };

    const arrowRef = useRef(null);
    let sliderImages = "";
    let objectFitStyle = objectFit ? {objectFit: 'cover'} : {objectFit: 'contain'}
    sliderImages = images.map((item, i) => (
        <div className="carousel__image" key={i}>
            <img style={objectFitStyle} src={item.img} alt="Nature Adventure img" />
        </div>
    ));

    return (
        <div className="carousel">
            <Slider ref={arrowRef} {...carouselSettings}>
                {sliderImages}
            </Slider>
            <div className="carousel__buttons">
                <button
                    onClick={() => arrowRef.current.slickPrev()}
                    className="back"
                >
                    <IoIosArrowBack />
                </button>
                <button
                    onClick={() => arrowRef.current.slickNext()}
                    className="next"
                >
                    <IoIosArrowForward />
                </button>
            </div>
        </div>
    );
}
