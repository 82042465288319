import React, { useEffect } from "react";
import "./Appointments.scss";

export function Appointments() {
    useEffect(() => {
        window.scrollTo(0, 0);

        const script = document.createElement("script");
        script.id = "zl-widget-s";
        script.src = "//platform.docplanner.com/js/widget.js";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <div className="appointments">
            <h1>Reserva tu cita</h1>
            <div className="appointments__calendly">
                <a
                    id="zl-url"
                    className="zl-url"
                    href="https://www.doctoralia.es/victoria-roldan-salazar/psicologo/granada"
                    rel="nofollow"
                    data-zlw-doctor="victoria-roldan-salazar"
                    data-zlw-type="big_with_calendar"
                    data-zlw-opinion="false"
                    data-zlw-hide-branding="true"
                    data-zlw-saas-only="false"
                >
                    Victoria Roldán Salazar - Doctoralia.es
                </a>
            </div>
        </div>
    );
}
