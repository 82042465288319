// TODO -> ENV VARIABLE
export const BASE_API = "http://localhost:4000";

export const routes = {
    index: "/",
    individual_therapy: "/terapia-individual",
    couple_therapy: "/terapia-pareja",
    group_workshops: "/talleres-grupales",
    about: "/sobre-mi",
    appointments: "/citas",
    cookie_policy: "/politica-cookies",
    privacy_policy: "/politica-privacidad",
};

export const social_networks = {
    email: "seapsicologia@gmail.com",
    instagram: "@seapsicologia",
    instagram_link: "https://www.instagram.com/seapsicologia",
    linkedin_link: "https://www.linkedin.com/in/victoriaroldanpsicologia",
};
