import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { routes, social_networks } from "../../../utils/constants";
import logo from "../../../assets/images/sea-psicologia-logo-home.svg";
import { RxHamburgerMenu, RxCross1 } from "react-icons/rx";
import { BiLogoInstagram, BiLogoLinkedin } from "react-icons/bi";
import "./NavBar.scss";

export function NavBar() {
    const [showOffcanvas, setShowOffcanvas] = useState(false);

    const toggleOffcanvas = () => {
        setShowOffcanvas(!showOffcanvas);
        document.body.classList.toggle("no-scroll", !showOffcanvas);
    };

    const openInstagramNetwork = () => {
        window.open(social_networks.instagram_link, "_blank"); // Abre la URL en una nueva pestaña
    };

    const openLinkedinNetwork = () => {
        window.open(social_networks.linkedin_link, "_blank"); // Abre la URL en una nueva pestaña
    };

    return (
        <>
            <div className="navbar">
                <div className="navbar__content">
                    <div className="navbar__content__links">
                        <NavLink
                            to={routes.index}
                            className="navbar__content__links-link"
                            activeclassname="active"
                            title="Inicio"
                        >
                            <p>Inicio</p>
                        </NavLink>
                        <NavLink
                            to={routes.individual_therapy}
                            className="navbar__content__links-link"
                            activeclassname="active"
                            title="Terapia individual"
                        >
                            <p>Terapia individual</p>
                        </NavLink>
                        <NavLink
                            to={routes.couple_therapy}
                            className="navbar__content__links-link"
                            activeclassname="active"
                            title="Terapia de pareja"
                        >
                            <p>Terapia de pareja</p>
                        </NavLink>
                        <NavLink
                            to={routes.group_workshops}
                            className="navbar__content__links-link"
                            activeclassname="active"
                            title="Talleres grupales"
                        >
                            <p>Talleres grupales</p>
                        </NavLink>
                        <NavLink
                            to={routes.about}
                            className="navbar__content__links-link"
                            activeclassname="active"
                            title="Sobre mi"
                        >
                            <p>Sobre mi</p>
                        </NavLink>
                        <NavLink
                            to={routes.appointments}
                            className="navbar__content__links-link"
                            activeclassname="active"
                            title="Pide cita"
                        >
                            <p>Pide cita</p>
                        </NavLink>
                    </div>
                </div>
                <div className="navbar__hamburger" onClick={toggleOffcanvas}>
                    <RxHamburgerMenu />
                </div>
            </div>

            <>
                <div
                    className={`overlay ${showOffcanvas ? "show" : ""}`}
                    onClick={toggleOffcanvas}
                ></div>

                <div
                    className={`offcanvas-wrapper ${
                        showOffcanvas ? "show" : ""
                    }`}
                >
                    <div className="offcanvas-wrapper__header">
                        <div
                            className="offcanvas-wrapper__header-close"
                            onClick={toggleOffcanvas}
                        >
                            <RxCross1 />
                        </div>
                        <div className="offcanvas-wrapper__header-logo">
                            <img src={logo} alt="SeaPsicología" />
                        </div>
                    </div>

                    <div className="offcanvas-wrapper__body">
                        <div className="offcanvas-wrapper__body-links">
                            <NavLink
                                to={routes.index}
                                className="offcanvas-wrapper__body-links__link"
                                activeclassname="active"
                                title="Inicio"
                                onClick={toggleOffcanvas}
                            >
                                <p>Inicio</p>
                            </NavLink>
                            <NavLink
                                to={routes.individual_therapy}
                                className="offcanvas-wrapper__body-links__link"
                                activeclassname="active"
                                title="Terapia individual"
                                onClick={toggleOffcanvas}
                            >
                                <p>Terapia individual</p>
                            </NavLink>
                            <NavLink
                                to={routes.couple_therapy}
                                className="offcanvas-wrapper__body-links__link"
                                activeclassname="active"
                                title="Terapia de pareja"
                                onClick={toggleOffcanvas}
                            >
                                <p>Terapia de pareja</p>
                            </NavLink>
                            <NavLink
                                to={routes.group_workshops}
                                className="offcanvas-wrapper__body-links__link"
                                activeclassname="active"
                                title="Talleres grupales"
                                onClick={toggleOffcanvas}
                            >
                                <p>Talleres grupales</p>
                            </NavLink>
                            <NavLink
                                to={routes.about}
                                className="offcanvas-wrapper__body-links__link"
                                activeclassname="active"
                                title="Sobre mi"
                                onClick={toggleOffcanvas}
                            >
                                <p>Sobre mi</p>
                            </NavLink>
                            <NavLink
                                to={routes.appointments}
                                className="offcanvas-wrapper__body-links__link"
                                activeclassname="active"
                                title="Pide cita"
                                onClick={toggleOffcanvas}
                            >
                                <p>Pide cita</p>
                            </NavLink>
                        </div>
                        <div className="offcanvas-wrapper__body-social">
                            <div
                                className="offcanvas-wrapper__body-social__network"
                                onClick={openInstagramNetwork}
                            >
                                <BiLogoInstagram />
                                <p>Instagram</p>
                            </div>
                            <div
                                className="offcanvas-wrapper__body-social__network"
                                onClick={openLinkedinNetwork}
                            >
                                <BiLogoLinkedin />
                                <p>Linkedin</p>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </>
    );
}
