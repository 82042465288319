import React from "react";
import { NavLink } from "react-router-dom";
import { routes } from "../utils/constants";

import "./Error404.scss";

export function Error404() {
    return (
        <div className="error404">
            <h1>Estás en una página erronea</h1>
            <div className="error404__appointment">
                <NavLink
                    to={routes.index}
                    className="navbar__content__links-link"
                    activeclassname="active"
                    title="Pide cita"
                >
                    <p>Conoce más sobre mí</p>
                </NavLink>
            </div>
        </div>
    );
}
