import React, { useEffect } from "react";
import logo from "../../../assets/images/sea-psicologia-logo-home.svg";
import victoria_roldan_seapsicologia from "../../../assets/images/victoria-roldan-sea-psicologia-home.jpg";
import { NavLink } from "react-router-dom";
import { routes } from "../../../utils/constants";
import { RiDoubleQuotesL, RiDoubleQuotesR } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { Carousel } from "../../../components/Public";
import review1 from "../../../assets/images/review1.svg";
import review2 from "../../../assets/images/review2.svg";
import review3 from "../../../assets/images/review3.svg";
import review4 from "../../../assets/images/review4.svg";
import review5 from "../../../assets/images/review5.svg";
import review6 from "../../../assets/images/review6.svg";

import "./Home.scss";

export function Home() {
    let reviews = [
        {
            img: review1,
            alt: "Reseña 1",
        },
        {
            img: review2,
            alt: "Reseña 2",
        },
        {
            img: review3,
            alt: "Reseña 3",
        },
        {
            img: review4,
            alt: "Reseña 4",
        },
        {
            img: review5,
            alt: "Reseña 5",
        },
        {
            img: review6,
            alt: "Reseña 6",
        },
    ];

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const navigate = useNavigate();

    return (
        <div className="home">
            <div className="home__header">
                <div className="home__header__content">
                    <img src={logo} alt="Sea Psicología" />
                    {/* <h2>Psicóloga General Sanitaria AO13017</h2> */}
                    <div className="home__header__content__buttons">
                        <div
                            className="home__header__content__buttons-appointment"
                            onClick={() => navigate(routes.appointments)}
                        >
                            Pide cita
                        </div>
                        <div
                            className="home__header__content__buttons-about-me"
                            onClick={() => navigate(routes.about)}
                        >
                            Sobre mí
                        </div>
                    </div>
                </div>
            </div>

            <div className="home__services">
                <div className="home__services-service home-service-individual-therapy">
                    <div className="home__services-service__content">
                        <h2>Terapia individual</h2>
                        <p className="home__services-service__content-description">
                            Trabajaremos juntos para explorar los desafíos
                            emocionales y mentales que estás enfrentando.
                        </p>
                        <NavLink
                            to={routes.individual_therapy}
                            className="navbar__content__links-link"
                            activeclassname="active"
                            title="Terapia individual"
                        >
                            <p>Leer más</p>
                        </NavLink>
                    </div>
                </div>
                <div className="home__services-service home-service-couple-therapy">
                    <div className="home__services-service__content">
                        <h2>Terapia de pareja</h2>
                        <p className="home__services-service__content-description">
                            Trabajaremos para ayudaros a encontrar soluciones
                            efectivas a vuestros problemas en la relación.
                        </p>
                        <NavLink
                            to={routes.couple_therapy}
                            className="navbar__content__links-link"
                            activeclassname="active"
                            title="Terapia de pareja"
                        >
                            <p>Leer más</p>
                        </NavLink>
                    </div>
                </div>
                <div className="home__services-service home-service-group-workshops">
                    <div className="home__services-service__content">
                        <h2>Talleres grupales</h2>
                        <p className="home__services-service__content-description">
                            Una gran oportunidad para aprender estrategias y
                            habilidades para mejorar tu bienestar emocional en
                            un ambiente de apoyo y comunidad.
                        </p>
                        <NavLink
                            to={routes.group_workshops}
                            className="navbar__content__links-link"
                            activeclassname="active"
                            title="Talleres grupales"
                        >
                            <p>Leer más</p>
                        </NavLink>
                    </div>
                </div>
            </div>

            <div className="home__about">
                <div className="home__about-approach">
                    <h2>Mi enfoque</h2>
                    <p>
                        Mi objetivo es ayudarte a conseguir las herramientas
                        necesarias para potenciar tu bienestar.
                    </p>
                    <p>
                        {" "}
                        Para ello, contemplo a las personas de manera integral,
                        trabajando sobre conducta, emoción y cognición a través
                        de técnicas propias de la Terapia Cognitivo Conductual y
                        de la Terapia de Aceptación y Compromiso.
                    </p>
                </div>
                <div className="home__about-picture">
                    <img
                        src={victoria_roldan_seapsicologia}
                        alt="Victora Roldán Sea Psicología"
                    />
                </div>
                <div className="home__about-text">
                    <h2>Sobre mí</h2>
                    <p>
                        Graduada en Psicología y con máster en Psicología
                        General Sanitaria. Durante mis años de formación, he
                        compaginado estos estudios habilitantes con diversos
                        másteres, expertos y cursos, lo que me permite trabajar
                        garantizando la mejor atención psicológica a las
                        personas que acompaño.
                    </p>
                    <NavLink
                        to={routes.about}
                        className="navbar__content__links-link"
                        activeclassname="active"
                        title="Sobre mi"
                    >
                        <p>Leer más</p>
                    </NavLink>
                </div>
            </div>

            <div className="home__philosophy">
                <h2>Filosofía de trabajo</h2>
                <p className="home__philosophy-description">
                    Mi filosofía de trabajo tiene como base el respeto y la
                    confidencialidad, generando un ambiente de confianza en el
                    que puedas sentirte seguro/a.
                </p>
                <p className="home__philosophy-description">
                    Concibo los problemas psicológicos como algo que le sucede a
                    la persona, no como algo que la persona es, por lo que
                    potencio el cambio a la hora de relacionarnos con estas
                    situaciones problemáticas.
                </p>
                <p className="home__philosophy-description">
                    Como cada persona y sus circunstancias son únicas, ofrezco
                    un tratamiento individualizado según tus necesidades, guiado
                    por tus valores, que nos servirán como herramientas de
                    cambio y desarrollo.
                </p>
                <div className="home__philosophy-steps">
                    <div className="home__philosophy-steps__step">
                        <div className="home__philosophy-steps__step-form__1">
                            1.
                        </div>
                        <p>Confianza</p>
                    </div>
                    <div className="home__philosophy-steps__step">
                        <div className="home__philosophy-steps__step-form__2">
                            2.
                        </div>
                        <p>Respeto</p>
                    </div>
                    <div className="home__philosophy-steps__step">
                        <div className="home__philosophy-steps__step-form__3">
                            3.
                        </div>
                        <p>Confidencialidad</p>
                    </div>
                    <div className="home__philosophy-steps__step">
                        <div className="home__philosophy-steps__step-form__4">
                            4.
                        </div>
                        <p>Plan individualizado</p>
                    </div>
                    <div className="home__philosophy-steps__step">
                        <div className="home__philosophy-steps__step-form__5">
                            5.
                        </div>
                        <p>Compromiso</p>
                    </div>
                </div>
            </div>

            <div className="home-carousel">
                <h2>Lo que dicen sobre mí las personas a las que acompaño</h2>
                <div className="slide">
                    <Carousel images={reviews} objectFit={false} />
                </div>
            </div>

            <div className="home__why-therapy">
                <h2>¿Por qué realizar terapia online?</h2>
                <h3>
                    La terapia psicológica en línea ha demostrado ser igual de
                    efectiva que la terapia psicológica realizada de manera
                    presencial y, además, puede ofrecer beneficios:
                </h3>
                <div className="home__why-therapy__cards">
                    <div className="home__why-therapy__cards-card">
                        <p className="home__why-therapy__cards-card__title">
                            Accesibilidad
                        </p>
                        <p className="home__why-therapy__cards-card__content">
                            Permiten que puedas conectarte con un profesional
                            desde cualquier lugar del mundo, siempre y cuando
                            tengas acceso a una conexión a internet. Sin
                            limitaciones de tiempo, ubicación o movilidad.
                        </p>
                    </div>
                    <div className="home__why-therapy__cards-card">
                        <p className="home__why-therapy__cards-card__title">
                            Comodidad
                        </p>
                        <p className="home__why-therapy__cards-card__content">
                            Permiten realizar las sesiones desde la comodidad de
                            tu hogar o desde cualquier lugar que te resulte
                            cómodo y seguro.
                        </p>
                    </div>
                    <div className="home__why-therapy__cards-card">
                        <p className="home__why-therapy__cards-card__title">
                            Flexibilidad
                        </p>
                        <p className="home__why-therapy__cards-card__content">
                            Te permite programar tus sesiones de acuerdo a tus
                            necesidades y horarios, lo que puede ser
                            especialmente beneficioso para aquellos con horarios
                            ocupados o impredecibles.
                        </p>
                    </div>
                    <div className="home__why-therapy__cards-card">
                        <p className="home__why-therapy__cards-card__title">
                            Menor coste
                        </p>
                        <p className="home__why-therapy__cards-card__content">
                            Debido a que se eliminan los costes de transporte y
                            otros gastos asociados con las sesiones
                            presenciales.
                        </p>
                    </div>
                </div>
            </div>

            <div className="home__sentence">
                <RiDoubleQuotesL />
                <p>
                    El equilibrio mental es la base de la salud de nuestro
                    cuerpo.{" "}
                </p>
                <RiDoubleQuotesR />
            </div>
        </div>
    );
}
