import React, { useEffect } from "react";
import { routes } from "../../../utils/constants";
import { NavLink } from "react-router-dom";

import "./CoupleTherapy.scss";

export function CoupleTherapy() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="couple-therapy">
            <div className="couple-therapy__header">
                <h1>Terapia de pareja</h1>
            </div>
            <div className="couple-therapy__content">
                <p>
                    Las sesiones de terapia de pareja suelen tener una duración
                    de 1 hora y media, y una frecuencia semanal. Durante este
                    tiempo, trabajaremos juntos para explorar los desafíos que
                    estáis enfrentando como pareja y ayudaros a encontrar
                    soluciones efectivas para superarlos.
                </p>
                <p>
                    En la primera sesión, nos enfocaremos en establecer una
                    relación de confianza y comprensión mutua, lo que ayudará a
                    crear un ambiente seguro para expresar los problemas que os
                    afectan como pareja. En las sesiones posteriores,
                    utilizaremos diferentes técnicas y enfoques de terapia para
                    ayudaros a comprender los patrones de comunicación y
                    comportamiento que puedan estar contribuyendo a vuestras
                    dificultades. El objetivo es ayudaros a aprender nuevas
                    formas de comunicaros y relacionaros entre sí, para mejorar
                    vuestro bienestar emocional y vuestra conexión como pareja.
                </p>
                <p>
                    Nos enfocaremos en vuestras necesidades como pareja, por lo
                    que adaptaré mi enfoque y técnicas para trabajar con ambos
                    de manera equitativa. Al asistir semanalmente, podréis
                    trabajar de manera constante en vuestros desafíos como
                    pareja y mantener la motivación necesaria para lograr los
                    objetivos terapéuticos.
                </p>
                <p>
                    Esta sesión es una oportunidad para explorar, reflexionar y
                    aprender nuevas formas de abordar vuestros desafíos como
                    pareja en un ambiente seguro y de apoyo. Estoy aquí para
                    ayudaros en este viaje y juntos trabajaremos para mejorar
                    vuestra relación y bienestar emocional.
                </p>
            </div>
            <div className="couple-therapy__prices">
                <div className="couple-therapy__prices-unique">
                    <h4>Sesión individual</h4>
                    <p>55€</p>
                </div>
                <div className="couple-therapy__prices-bonus">
                    <h4>Bono 4 sesiones</h4>
                    <p>175€</p>
                </div>
            </div>
            <div className="couple-therapy__extra-info">
                <p>* El bono es personal, intransferible y no reembolsable</p>
            </div>
            <div className="couple-therapy__appointment">
                <NavLink
                    to={routes.appointments}
                    className="navbar__content__links-link"
                    activeclassname="active"
                    title="Reserva tu cita"
                >
                    <p>Reserva tu cita</p>
                </NavLink>
            </div>
        </div>
    );
}
