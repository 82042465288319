import React from "react";
import { useLocation } from "react-router-dom";
import { NavBar, Footer } from "../../components/Public";
import { routes } from "../../utils/constants";

import "./PublicLayout.scss";

export function PublicLayout(props) {
    const { children } = props;
    const location = useLocation();

    const showIndTheBackground =
        location.pathname === routes.individual_therapy;
    const showCouTheBackground = location.pathname === routes.couple_therapy;
    const showGroWorBackground = location.pathname === routes.group_workshops;

    return (
        <div className="public-layout">
            <div
                className={
                    showIndTheBackground
                        ? "public-layout__individual-therapy-color"
                        : showCouTheBackground
                        ? "public-layout__couple-therapy-color"
                        : showGroWorBackground
                        ? "public-layout__group-workshops-color"
                        : "public-layout__white-color"
                }
            >
                <NavBar />
            </div>
            <div className="public-layout__main">{children}</div>
            <div className="public-layout__footer">
                <Footer />
            </div>
        </div>
    );
}
