import React, { useEffect } from "react";

import "./CookiePolicy.scss";

export function CookiePolicy() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="cookie-policy">
            <div className="cookie-policy__content">
                <h1>Política de Cookies</h1>

                <p>
                    A continuación, explicamos qué son las cookies y cómo las
                    utilizamos:
                </p>

                <h2>¿Qué son las cookies?</h2>
                <p>
                    Las cookies son pequeños archivos de texto que se almacenan
                    en su dispositivo cuando visita un sitio web que las usa.
                    Estas cookies permiten reconocer su dispositivo y mejorar su
                    experiencia de usuario.
                </p>

                <h2>Cookies utilizadas</h2>
                <p>Nuestro sitio web no usa ningún tipo de cookies.</p>

                <h2>Control de cookies</h2>
                <p>
                    Puede gestionar las preferencias de cookies en su navegador.
                </p>
            </div>
        </div>
    );
}
