import React, { useEffect } from "react";
import victoria_roldan_seapsicologia from "../../../assets/images/victoria-roldan-sea-psicologia-home.jpg";
import icon_list from "../../../assets/images/icon-list.svg";
import icon_list2 from "../../../assets/images/icon-list2.svg";

import "./About.scss";

export function About() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="about">
            <h2>Sobre mí</h2>
            <h1>Victoria Roldán Salazar. Colegiada AO13017</h1>
            <div className="about__header">
                <p className="about__header-summary">
                    Graduada en Psicología por la Universidad de Granada y con
                    Máster Oficial en Psicología General Sanitaria por la
                    Universidad de Zaragoza. Durante mis años de formación
                    oficial, he compaginado estos estudios habilitantes con
                    diversos másteres, expertos y cursos:
                </p>
                <div className="about__header-experience">
                    <div>
                        <img src={icon_list} alt="Icono Sea Psicología" />
                        <p> Máster en Atención Temprana</p>
                    </div>
                    <div>
                        <img src={icon_list} alt="Icono Sea Psicología" />
                        <p>
                            {" "}
                            Máster en Actualización e Intervención Psicológica y
                            Salud Mental
                        </p>
                    </div>
                    <div>
                        <img src={icon_list} alt="Icono Sea Psicología" />
                        <p> Experto en Terapia de Pareja</p>
                    </div>
                    <div>
                        <img src={icon_list} alt="Icono Sea Psicología" />
                        <p> Curso de Intervención en Trastornos Sexuales</p>
                    </div>
                    <div>
                        <img src={icon_list} alt="Icono Sea Psicología" />
                        <p>
                            {" "}
                            Cursos de tratamiento de Ansiedad, Ansiedad por
                            Enfermedad, Pánico con Agorafobia, Fobia Social,
                            Fobia Específica
                        </p>
                    </div>
                    <div>
                        <img src={icon_list} alt="Icono Sea Psicología" />
                        <p> Formación en Trastorno Obsesivo Compulsivo</p>
                    </div>
                </div>
                <div className="about__header-help-with">
                    <div className="about__header-help-with__text">
                        <h2>Puedo ayudarte con:</h2>
                        <div>
                            <img src={icon_list2} alt="Icono Sea Psicología" />
                            <p>Depresión</p>
                        </div>
                        <div>
                            <img src={icon_list2} alt="Icono Sea Psicología" />
                            <p>Ansiedad</p>
                        </div>
                        <div>
                            <img src={icon_list2} alt="Icono Sea Psicología" />
                            <p>Problemas de pareja</p>
                        </div>
                        <div>
                            <img src={icon_list2} alt="Icono Sea Psicología" />
                            <p>Fobias</p>
                        </div>
                        <div>
                            <img src={icon_list2} alt="Icono Sea Psicología" />
                            <p>Dificultades en las relaciones</p>
                        </div>
                        <div>
                            <img src={icon_list2} alt="Icono Sea Psicología" />
                            <p>Transiciones vitales</p>
                        </div>
                        <div>
                            <img src={icon_list2} alt="Icono Sea Psicología" />
                            <p>Dificultades con la autoestima</p>
                        </div>
                        <div>
                            <img src={icon_list2} alt="Icono Sea Psicología" />
                            <p>Trastorno Obsesivo Compulsivo (TOC)</p>
                        </div>
                        <div>
                            <img src={icon_list2} alt="Icono Sea Psicología" />
                            <p>Problemas profesionales</p>
                        </div>
                        <div>
                            <img src={icon_list2} alt="Icono Sea Psicología" />
                            <p>Manejo del estrés</p>
                        </div>
                        <div>
                            <img src={icon_list2} alt="Icono Sea Psicología" />
                            <p>Habilidades sociales</p>
                        </div>
                    </div>
                    <div className="about__header-help-with__image">
                        <img
                            src={victoria_roldan_seapsicologia}
                            alt="Victora Roldán Sea Psicología"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
