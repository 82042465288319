import routerPublic from "./routes.public";
import { Error404 } from "../pages";
import { PublicLayout } from "../layouts";

const routes = [
    ...routerPublic,
    {
        path: "*",
        layout: PublicLayout,
        component: Error404,
    },
];

export default routes;
