import { PublicLayout } from "../layouts";
import {
    Home,
    IndividualTherapy,
    CoupleTherapy,
    GroupWorkshops,
    About,
    Appointments,
    CookiePolicy,
    PrivacyPolicy,
} from "../pages/Public";

import { routes } from "../utils/constants";

const routesPublic = [
    {
        path: routes.index,
        layout: PublicLayout,
        component: Home,
    },
    {
        path: routes.individual_therapy,
        layout: PublicLayout,
        component: IndividualTherapy,
    },
    {
        path: routes.couple_therapy,
        layout: PublicLayout,
        component: CoupleTherapy,
    },
    {
        path: routes.group_workshops,
        layout: PublicLayout,
        component: GroupWorkshops,
    },
    {
        path: routes.appointments,
        layout: PublicLayout,
        component: Appointments,
    },
    {
        path: routes.about,
        layout: PublicLayout,
        component: About,
    },
    {
        path: routes.cookie_policy,
        layout: PublicLayout,
        component: CookiePolicy,
    },
    {
        path: routes.privacy_policy,
        layout: PublicLayout,
        component: PrivacyPolicy,
    },
];

export default routesPublic;
