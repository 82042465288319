import React, { useEffect } from "react";

import "./PrivacyPolicy.scss";

export function PrivacyPolicy() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="privacy-policy">
            <div className="privacy-policy__content">
                <h1>Política de Privacidad</h1>

                <p>
                    En Sea Psicología, valoramos y respetamos la privacidad de
                    nuestros visitantes. A continuación, detallamos cómo
                    manejamos la información recopilada durante su visita a
                    nuestro sitio web:
                </p>

                <h2>Información recopilada</h2>
                <p>
                    En este momento, nuestro sitio web no recopila datos
                    personales.
                </p>

                <h2>Uso de la información</h2>
                <p>
                    En este momento, nuestro sitio web no recopila datos
                    personales.
                </p>

                <h2>Cambios en la política de privacidad</h2>
                <p>
                    Nos reservamos el derecho de actualizar nuestra política de
                    privacidad en el futuro. Cualquier cambio se publicará en
                    esta página.
                </p>
            </div>
        </div>
    );
}
