import React, { useEffect } from "react";
import { routes } from "../../../utils/constants";
import { NavLink } from "react-router-dom";

import "./GroupWorkshops.scss";

export function GroupWorkshops() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="group-workshops">
            <div className="group-workshops__header">
                <h1>Talleres grupales</h1>
            </div>
            <div className="group-workshops__content">
                <p>
                    Los talleres grupales son sesiones de 2 horas que se
                    realizan en grupos de varias personas y son una oportunidad
                    para aprender habilidades y estrategias para mejorar tu
                    bienestar emocional en un ambiente de apoyo y comunidad.
                </p>
                <p>
                    Se llevan a cabo online y están diseñados para abordar temas
                    específicos, y se adaptan a las necesidades y experiencias
                    de los participantes. Durante los talleres, tendrás la
                    oportunidad de compartir tus experiencias y perspectivas con
                    otros participantes, lo que puede ayudarte a sentirte
                    comprendido y apoyado. Además, los talleres incluyen
                    actividades prácticas y ejercicios que te permiten aplicar
                    las habilidades y estrategias aprendidas en tu vida diaria.
                </p>
                <p>
                    Como paciente de nuestra terapia psicológica, tendrás la
                    oportunidad de participar en los talleres grupales como
                    parte complementaria a tu tratamiento.
                </p>
            </div>
            <div className="group-workshops__prices">
                <div className="group-workshops__prices-bonus">
                    <h4>Taller</h4>
                    <p>15€</p>
                </div>
            </div>
            <div className="group-workshops__appointment">
                <NavLink
                    to={routes.appointments}
                    className="navbar__content__links-link"
                    activeclassname="active"
                    title="Reserva tu cita"
                >
                    <p>Reserva tu cita</p>
                </NavLink>
            </div>
        </div>
    );
}
