import React from "react";
import { NavLink } from "react-router-dom";
import { BiLogoInstagram, BiLogoLinkedin } from "react-icons/bi";
import { social_networks, routes } from "../../../utils/constants";

import "./Footer.scss";

export function Footer() {
    const openInstagramNetwork = () => {
        window.open(social_networks.instagram_link, "_blank"); // Abre la URL en una nueva pestaña
    };

    const openLinkedinNetwork = () => {
        window.open(social_networks.linkedin_link, "_blank"); // Abre la URL en una nueva pestaña
    };

    return (
        <div className="footer">
            <div className="footer__info">
                <div className="footer__info-text">
                    <h3>SeaPsicología</h3>
                    <div>
                        <p>
                            Desde cualquier lugar, contigo en cada paso:
                            psicología online personalizada para tu bienestar
                            mental y emocional.
                        </p>
                    </div>
                </div>
                <div className="footer__info-contact">
                    <h3>Contacto</h3>
                    <div>
                        <p>Email: {social_networks.email}</p>
                    </div>
                </div>
                <div className="footer__info-social">
                    <h3>Redes sociales</h3>
                    <div
                        className="footer__info-social__network"
                        onClick={openInstagramNetwork}
                    >
                        <BiLogoInstagram />
                        <p>Instagram</p>
                    </div>
                    <div
                        className="footer__info-social__network"
                        onClick={openLinkedinNetwork}
                    >
                        <BiLogoLinkedin />
                        <p>Linkedin</p>
                    </div>
                </div>
            </div>
            <div className="footer__copyright">
                <NavLink
                    to={routes.privacy_policy}
                    className="navbar__content__links-link"
                    activeclassname="active"
                    title="Política de privacidad"
                >
                    <p>Política de privacidad</p>
                </NavLink>
                <p>&copy; 2023 - Copyright SeaPsicología</p>
                <NavLink
                    to={routes.cookie_policy}
                    className="navbar__content__links-link"
                    activeclassname="active"
                    title="Política de cookies"
                >
                    <p>Política de cookies</p>
                </NavLink>
            </div>
        </div>
    );
}
